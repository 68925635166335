import { IK } from "./i18n/keyStore";

/**논세라핀 장치 */
export const nonSerafinDeviceList = {
  TADs: "TADs",
  MARPE: "MARPE",
  Every10: "Every10",
  empty: "empty",
};

/**세라핀 장치 */
export const serafinDeviceList = {
  FixedBonded: "Fixed/Bonded",
  ClearAligner: "Clear aligner",
  WrapAround: "Wrap around",
  Hawley: "Hawley",
};

/**배열, 텍스트 정리 */
export const setListAndOther = (list, arr, text, t) => {
  const arrs = arr.map((item) => list[item]);
  if (!!text) arrs.push(`${t(IK.other)} : ${text}`);
  return arrs;
};

/** Results achieved 항목 */
export const achievedList = {
  ClassRelationshipAchieved: "Class I relationship achieved",
  ClassRelationshipMaintained: "Class I relationship maintained",
  FunctionalCanineGuidanceOnBothSides: "Functional canine guidance on both sides",
  AnteriorCrossbiteCorrected: "Anterior crossbite corrected",
  PosteriorCrossbiteCorrected: "Posterior crossbite corrected",
  DeepbiteImproved: "Deepbite improved",
  OpenbiteImproved: "Openbite improved",
  NormalOverjetAndOverbiteAchieved: "Normal overjet and overbite achieved",
  OverjetImproved: "Overjet improved",
  OverbiteImproved: "Overbite improved",
  SignificantImprovementOfOverjet: "Significant improvement of overjet",
  SignificantImprovementOfoverbite: "Significant improvement of overbite",
  MidlinesCoincident: "Midlines coincident",
  ProperAxialInclinationOfIncisors: "Proper axial inclination of incisors",
  CurveOfSpeeLeveled: "Curve of Spee leveled",
  ArchesAlignedAndCoordinated: "Arches aligned and coordinated",
  AlignmentOfBothArchesThroughDerotation: "Alignment of both arches through derotation",
  ArchFormImproved: "Arch form improved",
  ArchExpansionAchieved: "Arch expansion achieved",
  AestheticSmileLineWasAchieved: "Aesthetic smile line was achieved",
  AllTreatmentGoalsWereAchievedCase: "All treatment goals were achieved case",
  HarmonicArchesWereAchieved: "Harmonic arches were achieved",
};

/**배열 + 텍스트(기타) 출력 */

export const iDropImages = [
  { style: "occlusal_upper", name: "i_occlusal_upper" },
  { style: "intraoral_front", name: "i_intraoral_front" },
  { style: "occlusal_lower", name: "i_occlusal_lower" },
  { style: "buccal_right", name: "i_buccal_right" },
  { style: "overjet", name: "i_overjet" },
  { style: "buccal_left", name: "i_buccal_left" },
];

export const pDropImages = [
  { style: "occlusal_upper", name: "p_occlusal_upper" },
  { style: "intraoral_front", name: "p_intraoral_front" },
  { style: "occlusal_lower", name: "p_occlusal_lower" },
  { style: "buccal_right", name: "p_buccal_right" },
  { style: "overjet", name: "p_overjet" },
  { style: "buccal_left", name: "p_buccal_left" },
];

export const fDropImages = [
  { style: "occlusal_upper", name: "f_occlusal_upper" },
  { style: "intraoral_front", name: "f_intraoral_front" },
  { style: "occlusal_lower", name: "f_occlusal_lower" },
  { style: "buccal_right", name: "f_buccal_right" },
  { style: "overjet", name: "f_overjet" },
  { style: "buccal_left", name: "f_buccal_left" },
];
