import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetGalleryInfo } from "../../../apis/galleryApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import CommonImage from "../../../components/common/CommonImage";
import * as S3 from "../../../components/element/Button/style/ButtonLayout.style";
import { TitleV3 } from "../../../components/element/Title/TitleText";
import * as S2 from "../../../styles/Common";
import { achievedList, nonSerafinDeviceList, serafinDeviceList, setListAndOther } from "../../../utils/clinicalgalleryUtils";
import { clinicConditionArray } from "../../../utils/conditionItemUtils";
import { IK } from "../../../utils/i18n/keyStore";
import VideoPlayer from "../CorrectionImage/VideoPlayer";
import { SkeletonView } from "./SkeletonView";
import * as S from "./style/ClinicalCases.style";

function ClinicalCasesView() {
  const { t } = useTranslation(["translation"]);
  const { galleryId } = useParams();

  const { data, isLoading } = useGetGalleryInfo(galleryId);
  const clinicCaseInfo = data?.data;

  const [radiImg, setRadiImg] = useState("");
  const [seraVideo, setSeraVideo] = useState("");

  useEffect(() => {
    if (!isLoading) {
      setRadiImg([clinicCaseInfo?.i_panorama?.distFileName, clinicCaseInfo?.f_panorama?.distFileName]);
      setSeraVideo([clinicCaseInfo?.front_video_thumb?.distFileName, clinicCaseInfo?.front_video?.distFileName, clinicCaseInfo?.front_video?.fileId]);
    }
  }, [clinicCaseInfo, isLoading]);

  const ImgData = [
    {
      title: t(IK.treatment_before),
      titleStyle: "initial",
      imgs: [
        clinicCaseInfo?.i_occlusal_upper?.distFileName,
        clinicCaseInfo?.i_intraoral_front?.distFileName,
        clinicCaseInfo?.i_occlusal_lower?.distFileName,
        clinicCaseInfo?.i_buccal_right?.distFileName,
        clinicCaseInfo?.i_overjet?.distFileName,
        clinicCaseInfo?.i_buccal_left?.distFileName,
      ],
    },
    {
      title: t(IK.progress),
      titleStyle: "progress",
      imgs: [
        clinicCaseInfo?.p_occlusal_upper?.distFileName,
        clinicCaseInfo?.p_intraoral_front?.distFileName,
        clinicCaseInfo?.p_occlusal_lower?.distFileName,
        clinicCaseInfo?.p_buccal_right?.distFileName,
        clinicCaseInfo?.p_overjet?.distFileName,
        clinicCaseInfo?.p_buccal_left?.distFileName,
      ],
    },
    {
      title: t(IK.treatment_after),
      titleStyle: "final",
      imgs: [
        clinicCaseInfo?.f_occlusal_upper?.distFileName,
        clinicCaseInfo?.f_intraoral_front?.distFileName,
        clinicCaseInfo?.f_occlusal_lower?.distFileName,
        clinicCaseInfo?.f_buccal_right?.distFileName,
        clinicCaseInfo?.f_overjet?.distFileName,
        clinicCaseInfo?.f_buccal_left?.distFileName,
      ],
    },
  ];

  //방사선 사진 선택
  const panorama = [clinicCaseInfo?.i_panorama?.distFileName, clinicCaseInfo?.f_panorama?.distFileName];
  const cephalo = [clinicCaseInfo?.i_cephalo?.distFileName, clinicCaseInfo?.f_cephalo?.distFileName];
  const [activeRadiographButtonIndex, setActiveRadiographButtonIndex] = useState(0);

  //세라뷰 선택
  const front = [clinicCaseInfo?.front_video_thumb?.distFileName, clinicCaseInfo?.front_video?.distFileName, clinicCaseInfo?.front_video?.fileId];
  const multi = [clinicCaseInfo?.multi_video_thumb?.distFileName, clinicCaseInfo?.multi_video?.distFileName, clinicCaseInfo?.multi_video?.fileId];
  const [activeSeraviewButtonIndex, setActiveSeraviewButtonIndex] = useState(0);

  //구내 사진 선택
  const [introImgIndex, setIntroImgIndex] = useState(0);

  return (
    <CardInnerLayout>
      <CardTitle title={t(IK.clinical_gallery)} />
      {isLoading ? (
        <SkeletonView />
      ) : (
        <>
          {/* 환자 정보 */}
          <S.CaseHead>
            <S.CaseHeadBox>
              <S.CaseInfo>
                {/* 치료 옵션 */}
                <dl>
                  <dt>{t(IK.treatment_option)}</dt>
                  <dd>{t(IK[clinicCaseInfo?.packages])}</dd>
                </dl>

                {/* 임상상태 */}
                <dl>
                  <dt>{t(IK.clinical_condition)}</dt>
                  <dd>{clinicConditionArray(clinicCaseInfo?.conditions, t, clinicCaseInfo?.etc)}</dd>
                </dl>

                {/* 나이 */}
                <dl>
                  <dt>{t(IK.age)}</dt>
                  <dd>{clinicCaseInfo?.age}</dd>
                </dl>

                {/* 성별 */}
                <dl>
                  <dt>{t(IK.gender)}</dt>
                  <dd>{t(IK[clinicCaseInfo?.gender])}</dd>
                </dl>

                {/* 인종 */}
                <dl>
                  <dt>{t(IK.race)}</dt>
                  <dd>{!!clinicCaseInfo?.race ? t(IK[clinicCaseInfo.race.toLowerCase()]) : ""}</dd>
                </dl>

                {/* 치료 계획 */}
                <dl>
                  <dt>{t(IK.treatment_plan)}</dt>
                  <dd>
                    {clinicCaseInfo?.detail?.clinicalPlan?.split(";").map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                  </dd>
                </dl>
              </S.CaseInfo>
              <S.CaseHeadImg>
                <CommonImage filePath={clinicCaseInfo?.i_overjet?.distFileName} />
              </S.CaseHeadImg>
            </S.CaseHeadBox>
          </S.CaseHead>

          <S2.ContentBox $large>
            <S.CaseTextList>
              {/* 총 치료 기간 */}
              <dl>
                <dt>{t(IK.total_treatment_time)}</dt>
                <dd>
                  <p>
                    {clinicCaseInfo?.detail?.clinicalMonth} {t(IK.months)}
                  </p>
                </dd>
              </dl>

              {/* 장치 수 */}
              <dl>
                <dt>{t(IK.total_treatment_device)}</dt>
                <dd>
                  <p>
                    {t(IK.maxilla)} : {clinicCaseInfo?.detail?.summaryUpperArch}
                  </p>
                  <p>
                    {t(IK.mandible)} : {clinicCaseInfo?.detail?.summaryLowerArch}
                  </p>
                </dd>
              </dl>

              {/* 장치 착용 시간 */}
              <dl>
                <dt>{t(IK.aligner_wear_time)}</dt>
                <dd>
                  <p>
                    {t(IK.day)} {clinicCaseInfo?.detail?.clinicalTime}
                    {t(IK.hour_by_hour)}, {t(IK.per_device)} {clinicCaseInfo?.detail?.clinicalDay}
                    {t(IK.day_use)}
                  </p>
                </dd>
              </dl>

              {/* 세라핀 외 장치 */}
              <dl>
                <dt>{t(IK.gallery_other_device)}</dt>
                <dd>
                  {setListAndOther(nonSerafinDeviceList, clinicCaseInfo?.detail?.nonSerafinDevice, clinicCaseInfo?.detail?.nonSerafinDeviceText, t).map((item, i) => (
                    <p key={i}>{item === "empty" ? t(IK.empty) : item}</p>
                  ))}
                </dd>
              </dl>

              {/* 유지장치 */}
              <dl>
                <dt>{t(IK.gallery_devide_check)}</dt>
                <dd>
                  {setListAndOther(serafinDeviceList, clinicCaseInfo?.detail?.serafinDevice, clinicCaseInfo?.detail?.serafinDeviceText, t).map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </dd>
              </dl>

              {/* Results achieved */}
              <dl>
                <dt>Results achieved</dt>
                <dd>
                  {setListAndOther(achievedList, clinicCaseInfo?.detail?.clinicalAchieved, clinicCaseInfo?.detail?.clinicalAchievedText, t).map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </dd>
              </dl>
            </S.CaseTextList>
          </S2.ContentBox>

          {/* 구내 사진 */}
          <S2.ContentBox $large>
            <TitleV3 title={t(IK.intraoral)} />
            <S.CaseImgList>
              {ImgData.map((data) => (
                <ClinicalCaseImg key={data.title} imgs={data.imgs} title={data.title} titleStyle={data.titleStyle} setIntroImgIndex={setIntroImgIndex} introImgIndex={introImgIndex} />
              ))}
            </S.CaseImgList>
          </S2.ContentBox>

          {/* 방사선 사진 */}
          <S2.ContentBox $large>
            <TitleV3 title={t(IK.radiograph)} />
            <S.CaseImgList>
              <S.CaseImgItem $initial>
                <h3>{t(IK.treatment_before)}</h3>
                <div>{radiImg && <CommonImage filePath={radiImg[0]} />}</div>
              </S.CaseImgItem>
              <S.CaseImgItem $final>
                <h3>{t(IK.treatment_after)}</h3>
                <div>{radiImg && <CommonImage filePath={radiImg[1]} />}</div>
              </S.CaseImgItem>
            </S.CaseImgList>
            <S.CaseTab>
              <CaseTextBtn
                imgData={panorama}
                text="Pano"
                onView={(imgData) => {
                  setRadiImg(imgData);
                  setActiveRadiographButtonIndex(0);
                }}
                active={activeRadiographButtonIndex === 0}
              />
              <CaseTextBtn
                imgData={cephalo}
                text="Ceph"
                onView={(imgData) => {
                  setRadiImg(imgData);
                  setActiveRadiographButtonIndex(1);
                }}
                active={activeRadiographButtonIndex === 1}
              />
            </S.CaseTab>
          </S2.ContentBox>

          {/* 세라뷰 */}
          <S2.ContentBox $large>
            <TitleV3 title={t(IK.seraview)} />
            <S.CaseVideo>{seraVideo && <VideoPlayer distFileName={seraVideo[1]} fileId={seraVideo[2]} />}</S.CaseVideo>
            <S.CaseTab>
              <CaseBtn
                imgData={front}
                onView={(imgData) => {
                  setSeraVideo(imgData);
                  setActiveSeraviewButtonIndex(0);
                }}
                active={activeSeraviewButtonIndex === 0}
              />
              <CaseBtn
                imgData={multi}
                onView={(imgData) => {
                  setSeraVideo(imgData);
                  setActiveSeraviewButtonIndex(1);
                }}
                active={activeSeraviewButtonIndex === 1}
              />
            </S.CaseTab>
          </S2.ContentBox>
        </>
      )}

      <CardTail line>
        <S3.ButtonLtBox>
          <S3.StyledButton to="/education/clinical-cases-list">{t(IK.list)}</S3.StyledButton>
        </S3.ButtonLtBox>
      </CardTail>
    </CardInnerLayout>
  );
}

export default ClinicalCasesView;

//치료 종류 사진 선택
export const ClinicalCaseImg = ({ imgs, titleStyle, title, introImgIndex, setIntroImgIndex }) => {
  return (
    <S.CaseImgItem $initial={titleStyle === "initial"} $final={titleStyle === "final"} $progress={titleStyle === "progress"}>
      <h3>{title}</h3>
      <div>
        <CommonImage filePath={imgs[introImgIndex]} />
      </div>
      <ul>
        {imgs.map((img, i) => (
          <li key={i}>
            <S.CaseImgItemBtn
              type="button"
              onClick={() => {
                setIntroImgIndex(i);
              }}
              $active={i === introImgIndex}
            >
              <CommonImage filePath={img} />
            </S.CaseImgItemBtn>
          </li>
        ))}
      </ul>
    </S.CaseImgItem>
  );
};

// 방사선 사진 선택
export const CaseTextBtn = ({ imgData, text, onView, active }) => {
  return (
    <S.CaseTabBtn $active={active} onClick={() => onView(imgData)}>
      {text}
    </S.CaseTabBtn>
  );
};

// 세라뷰 사진 선택
export const CaseBtn = ({ imgData, twoBtn, onView, active }) => {
  return (
    <S.CaseTabBtn $active={active} onClick={() => onView(imgData)}>
      <CommonImage filePath={imgData[0]} />
      {twoBtn && <CommonImage filePath={imgData[1]} />}
    </S.CaseTabBtn>
  );
};
