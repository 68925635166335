import sortIco1 from "../../src/assets/images/patients/list/sort_ico1.png";
import sortIco2 from "../../src/assets/images/patients/list/sort_ico2.png";
import sortIco3 from "../../src/assets/images/patients/list/sort_ico3.png";
import sortIco4 from "../../src/assets/images/patients/list/sort_ico4.png";
import sortIco5 from "../../src/assets/images/patients/list/sort_ico5.png";
import sortIco6 from "../../src/assets/images/patients/list/sort_ico6.png";
import sortIco7 from "../../src/assets/images/patients/list/sort_ico7.png";
import { IK } from "./i18n/keyStore";

/**즐찾 아이콘 파일 반환 */
export const sortIcons = {
  0: sortIco1,
  1: sortIco2,
  2: sortIco3,
  3: sortIco4,
  4: sortIco5,
  5: sortIco6,
  6: sortIco7,
};

export const getSortIcon = (code) => {
  return code ? sortIcons[code] : sortIco1;
};

export const sortIconList = [
  { code: "DEFAULT", icon: sortIco1 },
  { code: "YELLOW", icon: sortIco2 },
  { code: "GREEN", icon: sortIco3 },
  { code: "RED", icon: sortIco4 },
  { code: "PURPLE", icon: sortIco5 },
  { code: "BLACK", icon: sortIco6 },
];

export const TableSortIconList = [
  { code: 0, icon: sortIco1 },
  { code: 1, icon: sortIco2 },
  { code: 2, icon: sortIco3 },
  { code: 3, icon: sortIco4 },
  { code: 4, icon: sortIco5 },
  { code: 5, icon: sortIco6 },
  { code: 6, icon: sortIco7 },
];

/**반려사유 */
export const rejectReasonList = {
  REJECT_SCAN: IK.reject_scan,
  REJECT_MODEL: IK.reject_model,
  REJECT_OCCLUSION: IK.reject_occlusion,
  NO_TREATMENT_PLAN_REMAINING: IK.no_treatment_plan_remaining,
  REJECT_ETC: IK.other,
};
