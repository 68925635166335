import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "swiper/css";
import "swiper/css/navigation";
import { useGetPatientStudyList } from "../../../../apis/patientApi";
import { useGetRecentStudy, useGetStudyProgress, useGetStudySwiperList } from "../../../../apis/studyApi";
import { CardTail } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S3 from "../../../../styles/Common";
import { IK } from "../../../../utils/i18n/keyStore";
import { SkeletonStudyCard } from "./SkeletonStudyCard";
import StudyHistoryModal from "./profileModal/StudyHistoryModal";
import StudyHistoryList from "./studyList/StudyHistoryList";
import StudyPickList from "./studyList/StudyPickList";
import StudySwiper from "./studyList/StudySwiper";

function StudyList({ patientId }) {
  const { t } = useTranslation(["translation"]);

  /**본처방전 치료옵션 조회*/
  const { data, isLoading } = useGetPatientStudyList(patientId);
  const studyOrderHistorty = data?.data;

  /** 선택된 스터디 (상위)*/
  const [selectedStudy, setSelectedStudy] = useState({});

  /**가장 최근 치료옵션에 해당하는 스터디 선택 */
  useEffect(() => {
    if (!isLoading && studyOrderHistorty?.length > 0) {
      setSelectedStudy({ ...studyOrderHistorty[0], patientId });
    }
  }, [isLoading, studyOrderHistorty, patientId]);

  /**해당 스터디의 처방전 아이디 (하위) */
  const [selectedId, setSelectedId] = useState({});
  /**해당 처방전의 마지막 상탯값 */
  const [lastDetail, setLastDetail] = useState();
  /**해당 처방전이 추가처방전이라면 edit/none 판단 */
  const [isAdditionalEdit, setIsAdditionalEdit] = useState(false);

  /**선택된 스터디의 처방전 상태 정보 리스트 조회 */
  const { data: StudyStatusData, isLoading: isStatusLoading } = useGetStudyProgress(selectedStudy.patientId, selectedStudy.studyId, selectedId);
  const studyStatusList = StudyStatusData?.data;

  /**선택된 스터디의 스와이퍼 리스트 */
  const { data: swiperData, isLoading: isSwiperLoading } = useGetStudySwiperList(selectedStudy.patientId, selectedStudy.studyId);
  const studySwiperList = swiperData?.data;

  /**가장 최근에 작성된 처방전 조회*/
  const { data: recentData, isLoading: isRecentLoading } = useGetRecentStudy(selectedStudy.patientId, selectedStudy.studyId);
  const recentStudy = recentData?.data;

  /**최근 작성된 아이디와 처방전 상태를 설정 */
  useEffect(() => {
    if (!isRecentLoading && recentStudy?.deviceName) {
      setSelectedId({ [recentStudy.deviceName]: recentStudy.deviceId });
      setLastDetail(recentStudy.latestDetail);
      setIsAdditionalEdit(recentStudy.deviceName === "additional" ? recentStudy.additionalProcess : false);
    }
  }, [isRecentLoading, recentStudy, selectedStudy]);

  /**모든 로딩 */
  const isLoadingAll = isLoading || isSwiperLoading || isRecentLoading;

  /**처방전 내역 히스토리 조회 */
  const [historyOpen, setHistoryOpen] = useState(false);

  /**처방전 내역 히스토리 모달 열기 */
  const openHistory = () => setHistoryOpen(true);
  /**처방전 내역 히스토리 모달 닫기 */
  const closeHistory = () => setHistoryOpen(false);

  return (
    <>
      {isLoadingAll ? (
        <SkeletonStudyCard />
      ) : (
        <S.CardColWd $full $hidden>
          {Object.keys(selectedId)?.length > 0 && (
            <>
              <StudySwiper studySwiperList={studySwiperList} setSelectedId={setSelectedId} selectedId={selectedId} setLastDetail={setLastDetail} setIsAdditionalEdit={setIsAdditionalEdit} />
              <S.CardInner>
                <S.CardTitle>
                  <S3.TextDiv as="h2">
                    {t(IK[selectedStudy.packages])} ({dayjs(selectedStudy?.createdDate).format("YYYY-MM-DD")})
                    {lastDetail === "22" && <S3.TextDiv $textColorBlue400>{t(IK.end_of_treatment)}</S3.TextDiv>}
                  </S3.TextDiv>
                </S.CardTitle>

                {/**환자의 치료옵션에 따른 처방전 상태*/}
                {isStatusLoading ? (
                  <Skeleton height={320} />
                ) : (
                  <StudyHistoryList studyStatusList={studyStatusList} lastDetail={lastDetail} selectedId={selectedId} selectedStudy={selectedStudy} isAdditionalEdit={isAdditionalEdit} />
                )}

                <CardTail line>
                  <S2.ButtonLtBox>
                    <S2.StyledButton to="/patient">{t(IK.list)}</S2.StyledButton>
                    <S2.StyledButton as="button" type="button" $primary onClick={openHistory}>
                      {t(IK.study_history)}
                    </S2.StyledButton>
                  </S2.ButtonLtBox>
                </CardTail>
              </S.CardInner>
            </>
          )}
          {/**환자의 치료옵션에 따른 스터디 종류, 2개 이상부터 표출 */}
          {studyOrderHistorty?.length > 1 && <StudyPickList studyOrderHistorty={studyOrderHistorty} selectedStudy={selectedStudy} setSelectedStudy={setSelectedStudy} />}
          {historyOpen && (
            <StudyHistoryModal historyOpen={historyOpen} closeHistory={closeHistory} patientId={patientId} studyId={selectedStudy.studyId} selectedId={selectedId} lastDetail={lastDetail} />
          )}
        </S.CardColWd>
      )}
    </>
  );
}

export default StudyList;
