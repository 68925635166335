import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Table/style/table.style";
import { IK } from "../../../../../utils/i18n/keyStore";

export const PatientClinicalCasesModal = ({ setClinicalCasesModalOpen, clinicalCasesModalOpen, clinicalCases }) => {
  const { t } = useTranslation(["translation"]);

  const closeClinicalCasesModal = () => setClinicalCasesModalOpen(false);

  return (
    <Modal open={clinicalCasesModalOpen} close={closeClinicalCasesModal} header={t(IK.clinical_cases_submission)}>
      <S.TableType1>
        <colgroup>
          <col width="200px"></col>
          <col width="80px"></col>
        </colgroup>
        <thead>
          <tr>
            <th>{t(IK.treatment_option)}</th>
            <th>{t(IK.select)}</th>
          </tr>
        </thead>
        <tbody>
          {clinicalCases?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{`${t(IK[item.packages])} (${dayjs(item?.createdDate).format("YYYY-MM-DD")})`}</td>
                <td>
                  <S2.StyledSmallButton to={`/patient/${item.patientId}/study/${item.studyId}/clinical-cases-register`}>{t(IK.submitted)}</S2.StyledSmallButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </S.TableType1>
    </Modal>
  );
};
